<template>
	<main class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>MY 서비스</li>
							<li>나의 서비스</li>
						</ul>
					</div>
					<h2 class="page_title">나의 서비스</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="account_info">
						<b>{{ getSessionProc()?.emailId }}</b> 님께서 신청한 서비스 이용 정보입니다.
					</div>
					<div class="tbl_input">
						<table>
							<tr>
								<th>주문번호</th>
								<td>
									<div class="text_box">
										{{ service.orderNumber }}
									</div>
								</td>
							</tr>
							<tr>
								<th>프로젝트명</th>
								<td>
									<div class="text_box">
										{{ service.projectName }}
									</div>
								</td>
							</tr>
							<tr>
								<th>회사명</th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" :value="service.companyName">
									</div>
								</td>
							</tr>
							<tr>
								<th>담당자명</th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" :value="service.managerName">
									</div>
								</td>
							</tr>
							<tr>
								<th>색상코드</th>
								<td>
									<div class="text_box">
										{{ service.backgroundColor }}
									</div>
								</td>
							</tr>
							<tr>
								<th>회사로고</th>
								<td>
									<div class="inp_box i1b2 file_box">
										<input type="text" class="inp file_name" disabled v-model="filename"
										       placeholder="파일은 최대 용량 10MB 이하로 등록해 주세요.">
										<input type="file" class="inp_file" @change="handleFileChange">
										<button type="button" class="btn btn_upload" @click="clickFileInput">파일 찾기
										</button>
										<button type="button" class="btn" @click="deleteFile">삭제</button>
									</div>
								</td>
							</tr>
							<tr>
								<th>수집 요청 기간</th>
								<td>
									<div class="text_box">
										{{ service.reqDate }}
									</div>
								</td>
							</tr>
							<tr>
								<th>추가 요청사항</th>
								<td>
									<div class="text_box">
										{{ service.reqMsg }}
									</div>
								</td>
							</tr>
							<tr>
								<th>수집 희망 채널</th>
								<td>
									<div class="text_box">
										{{ service.reqChannel | requestChannelFilter }}
									</div>
								</td>
							</tr>

							<!-- 카페24 테이블 -->
							<template class="tbl_input" v-if="service.reqChannel==='cafe'">
								<tr>
									<th rowspan="4">
										카페24 API KEY 정보<br>
										<a href="#" class="btn_th">정보 확인 가이드</a>
									</th>
									<td>
										<div class="inp_box i1">
											<input type="text" class="inp" v-model="service.clientId">
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div class="inp_box i1">
											<input type="text" class="inp" v-model="service.secretKey">
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div class="inp_box i1">
											<input type="text" class="inp" v-model="service.mailId">
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div class="inp_box i1">
											<input type="text" class="inp" v-model="service.redirectUrl">
										</div>
									</td>
								</tr>
							</template>

							<!-- 고도몰 테이블 -->
							<template class="tbl_input" v-if="service.reqChannel==='godo'">
								<tr>
									<th rowspan="2">
										고도몰 API KEY 정보<br>
										<a href="#" class="btn_th">정보 확인 가이드</a>
									</th>
									<td>
										<div class="inp_box i1">
											<input type="text" class="inp" v-model="service.partnerId">
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div class="inp_box i1">
											<input type="text" class="inp" v-model="service.secretKey">
										</div>
									</td>
								</tr>
							</template>

							<!-- ERP 테이블 -->
							<template class="tbl_input" v-if="service.reqChannel==='erp'">
								<tr>
									<th>ERP</th>
									<td>
										<div class="inp_box i1">
											<input type="text" class="inp" v-model="service.erpName">
										</div>
									</td>
								</tr>
							</template>

							<!-- 고객행동데이터 테이블 -->
							<template class="tbl_input" v-if="service.reqChannel==='data'">
								<tr>
									<th>고객행동데이터<br>(수집 희망 웹사이트 주소)</th>
									<td>
										<div class="inp_box i1">
											<input type="text" class="inp" v-model="service.webUrl">
										</div>
									</td>
								</tr>
							</template>

						</table>
					</div>

					<div class="button_area">
						<button type="button" class="btn_cancle btn_black" @click="goBack">취소</button>
						<button type="submit" class="btn_submit btn_primary" @click="doSubmit">저장</button>
						<button type="submit" class="btn_quit btn_red" v-if=" service.paymentType != 'stop' && service.statusCode == 'confirm' " @click="stopMyService">서비스 해지</button>
					</div>

				</div>
			</div>

		</div>
	</main>
</template>

<script>
import $ from "jquery";
import moment from 'moment'

export default {
	name: "myView",
	data() {
		return {
			idx: this.$route.query.idx,
			service: {},
			file: {},
			filename: '',
			changeFile: false
		}
	},
	mounted() {
		if(this.getSessionProc()==null || this.getSessionProc().emailId == null) {
			return self.swalUtils.gritter("로그인이 필요합니다", "", "success", 3000)
					.then( () => {
						location.href = '/'
					});
		}

		this.getMyServiceOne()
	},
	methods: {
		momentFilter(date) {
			return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
		},

		stopMyService() {
			console.log('ssss')

			this.$eventBus.$emit("startProgress")

			const self = this;

			// 무료체험이면 중지
			if (this.service.paymentType == 'free') {

				this.getMyServiceOne();

				this.service.paymentType = 'free';

				this.axiosCaller.servicePost(this, this.APIs.MY_SERVICE + "/saveMyService", this.service, (res) => {
					const result = res.data
					console.log(result)
					if (result.status === "ok") {
						self.swalUtils.gritter('서비스 해지가 정상적으로 완료되었습니다', "", "success", 3000)
								.then( () => {
									location.href = '/myService/myList'
								});
					} else {
						self.swalUtils.gritter(result.msg, "", "error", 3000)
					}
					this.$eventBus.$emit("doneProgress")
				});


			} // 정기결제중이면 서비스 환불 후 중지
			else {

				let endDate = this.service.endDate
				let parseEndDate = new Date(Date.parse(endDate))

				// 오늘
				let now = new Date();
				// 종료일 - 3일전
				let threeDayAgo = new Date(parseEndDate.setDate(parseEndDate.getDate() - 3));

				// 비교하게 세팅
				let eDate = this.momentFilter(threeDayAgo);  // 종료 - 3일
				let tday = this.momentFilter(now);   // 오늘

				// 오늘이 종료3일전 같거나 크면 안됨. 같으면 알림창.
				if (tday >= eDate) {
					//console.log('종료일 임박. 혹은 넘음.')
					return this.swalUtils.gritter("정기 결제일 3일전에는 서비스 종료 및 환불이 불가합니다", "INFO")
				}

				// 결제 중지로 세팅 함.
				this.service.paymentType = "stop";

				// 환불 일수 구하기
				let gapEdate = new Date(Date.parse(endDate));
				let gap = gapEdate.getTime() - now.getTime();
				let gapDate = Math.ceil(gap / (1000 * 60 * 60 * 24));

				// 환불 금액 구하기
				let refundCost;
				let calCost = 3300 * gapDate

				// FIXME 나중에 월요금이 변경될시 함께 연동시켜 변경해야함함
				let monthCost = 99000;

				// 게산금액이 월금액보다 클수도 있음
				// 그러면 월금액으로 환불한다 ( 월금액을 최대 환불금으로 함. )
				if (calCost > monthCost) {
					refundCost = monthCost;
				} else {
					refundCost = calCost;
				}


				//stopMyService
				// { 'price' : refundCost, 'reqChannel' : this.service.ServiceApplyVO.reqChannel, 'orderNumber' : this.service.ServiceApplyVO.orderNumber }
				this.axiosCaller.post(this, this.APIs.MY_SERVICE + "/stopMyService",
						{ 'price' : refundCost, 'reqChannel' : this.service.reqChannel, 'orderNumber' : this.service.orderNumber }, (res) => {
							const result = res.data
							console.log(result)
							if (result.status === "ok") {

								if( "00" == result.resultCode ) {
									self.swalUtils.gritter('서비스 해지가 정상적으로 완료되었습니다', "", "success", 3000)
								} else {
									self.swalUtils.gritter(result.resultMsg, "", "error", 3000)
								}

								this.$eventBus.$emit("doneProgress")

							} else {
								self.swalUtils.gritter(result.msg, "", "error", 3000)
							}
							this.$eventBus.$emit("doneProgress")
						});
			}

		},

		goBack() {
			history.back()
		},

		clickFileInput() {
			this.deleteFile();
			//$(".inp_file").click();
		},

		//파일 교체
		handleFileChange(e) {
			this.logoFile = e.target.files[0];
			this.filename = e.target.files[0].name;
			this.changeFile = true
		},

		deleteFile() {
			let agent = navigator.userAgent.toLowerCase();
			if ((navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1)) {
				// ie 일때 input[type=file] init.
				$(".inp_file").replaceWith($(".inp_file").clone(true));
			} else {
				//other browser 일때 input[type=file] init.
				$(".inp_file").val("");
			}
			this.filename = "";
		},

		doSubmit() {

			if(this.isLogin()) {
				this.$eventBus.$emit("startProgress")

				// // 파일 수정했으면 파일 업로드 후 저장
				// // 아니면 그냥 저장
				if(this.changeFile) {
					this.uploadFile()
				} else {
					this.saveMyService()
				}
			}

		},

		//파일 업로드
		uploadFile() {
			let self = this;
			let formData = new FormData;
			formData.append('files', this.logoFile)
			formData.append('LOGIN_ID', this.getSessionProc().emailId)

			this.axiosCaller.postFormData(this, this.APIs.BOARD_FILES + "/upload", formData, (res) => {
				this.$eventBus.$emit("doneProgress")
				const result = res.data
				if (result.status === "ok") {
					self.service.fidx = result.fileList[0].fidx;
					self.saveMyService();
				} else {
					console.log('file upload 이슈')
				}
			})
		},

		saveMyService() {

			const self = this;

			this.axiosCaller.servicePost(this, this.APIs.MY_SERVICE + "/saveMyService", this.service, (res) => {
				const result = res.data
				console.log(result)
				if (result.status === "ok") {
					self.swalUtils.gritter(result.msg, "", "success", 3000)
						.then( () => {
							location.href = '/myService/myList'
						});
				} else {
					self.swalUtils.gritter(result.msg, "", "error", 3000)
				}
				this.$eventBus.$emit("doneProgress")
			});

		},

		getMyServiceOne() {
			const self = this;

			let temp = {}
			this.$eventBus.$emit("startProgress")

			temp.idx = this.idx
			temp.emailId = this.getSessionProc().emailId;

			this.axiosCaller.get(this, this.APIs.MY_SERVICE + "/getMyServiceOne", temp, (res) => {
				var result = res.data
				if (result.status === "ok") {

					if (result.service == null) {
						this.swalUtils.gritter("잘못된 접근입니다.", result.msg, "error").then(() => {
							//self.$router.push('/')
							history.back();
						});
					}

					self.service = result.service;
					self.file = result.file != undefined ? result.file : null;
					if( self.file != null ){ self.filename = self.file.filename }

				} else {
					this.swalUtils.gritter("Error", result.msg, "error")
				}
				this.$eventBus.$emit("doneProgress")
			})

		}



	}
}
</script>

<style scoped>

</style>